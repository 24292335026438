var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "survey-container" },
    [
      _vm.error
        ? _c("div", { staticClass: "error" }, [
            _vm._v(" " + _vm._s(_vm.error) + " "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.visible
        ? _c("Survey", {
            attrs: {
              json: _vm.json,
              answers: _vm.answers,
              pageChangedHandler: _vm.pageChangedHandler,
              onValueChangedHandler: _vm.onValueChangedHandler,
            },
            on: {
              onPartialSend: _vm.onPartialSend,
              onComplete: _vm.onComplete,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }