import Vue from "vue";
const Survey = require("survey-vue/survey.vue.js");
import "survey-vue/survey.css";

export default {
  props: ["json", "answers", "onPartialSend", "onComplete", "pageChangedHandler", "onValueChangedHandler"],
  components: {
    survey: Survey.Survey
  },
  data: () => ({
    survey: null
  }),
  mounted() {
    console.log('survey mount');
    const survey = new Survey.Model(this.$props.json);
    survey.data = this.$props.answers;
    if (survey.data.__currentPageNo)
      survey.currentPageNo = survey.data.__currentPageNo;
    this.$data.survey = survey;
    survey.sendResultOnPageNext = true;
    survey.onPartialSend.add(model => this.$emit("onPartialSend", model));
    survey.onComplete.add(model => this.$emit("onComplete", model));

    if (this.$props.pageChangedHandler) {
      survey.onCurrentPageChanged.add(this.$props.pageChangedHandler);
    }

    if (this.$props.onValueChangedHandler) {
      survey.onValueChanged.add(this.$props.onValueChangedHandler);
    }

  }
};
