<template>
  <div class="survey-container">
    <div class="error" v-if="error"> {{ error }} </div>
    <Survey v-if="visible" 
      :json="json" 
      :answers="answers"
      @onPartialSend="onPartialSend"
      @onComplete="onComplete" 
      :pageChangedHandler="pageChangedHandler"
      :onValueChangedHandler="onValueChangedHandler" />
  </div>
</template>

<script>
import Survey from "./Survey/Survey.vue";
export default {
  name: "App",
  components: { Survey },
  computed: {
    controller: function() {
      return this.$root.$options.controller;
    },
    controllerData: function() {
      return this.controller.data;
    },
    error: function() {
      return this.controllerData.globalError;
    },
    json: function() {
      return this.controllerData.surveyJson;
    },
    visible: function() {
      return !this.error && !!this.json;
    },
    answers: function() {
      return this.controllerData.answers;
    },
    pageChangedHandler: function() {
      return this.controller.options.pageChangedHandler;
    },
    onValueChangedHandler: function() {
      return this.controller.options.onValueChanged;
    }
  },
  methods: {
    onPartialSend: function(model) {
      this.sendAnswers(model, false);
    },
    onComplete: function(model) {
      this.sendAnswers(model, true);
      if (this.controller.options.onComplete)
        this.controller.options.onComplete();
    },
    sendAnswers: function(model, isComplete) {
      this.controller.onSendAnswers(model, isComplete);
    }
  }
};
</script>

<style lang="stylus">
.survey-container
  min-height 50vh
  display flex
  .error
    margin auto
    font-family Open Sans, sans-serif
    color #323232
    font-size 25px
    line-height 30px
    text-align center
    max-width 80%
</style>